import { createInstance, createLocaleApi, Piral, PiralInstance } from "piral";
import { home, layout } from "./layouts/layout";
import React from "react";
import piletService from "./services/PiletService";
import { AppshellUtil } from "./utils/appshellUtil";
import { Localizer } from "./i18n/localizer";
import { createMyApi, MyPiletApi } from "./piralCustomApi";
import * as actions from "./piralActions";
import { errors } from "./layouts/errors/errors";
import RoutesEnum from "./constants/routesEnum";
import { createRoot } from "react-dom/client";
import { useGtm } from "@gnv/resevo-libportal-web";
import HomePageUI from "./components/homePageUI/HomePageUI";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import "./styles/style.scss"; // Ensure SCSS loads after Emotion

// interface augmentation -- adding menuDisabled and loadingPilets and menu custom actions
declare module "piral-core/lib/types/custom" {
  interface PiralCustomActions {
    disableAppMenu(): void;

    enableAppMenu(): void;

    setMenuOpen(): void;

    setMenuClosed(): void;
  } // interface augmentation for the custom actions

  interface PiletCustomApi extends MyPiletApi {} // adding plugin apis

  interface PiralCustomState {
    menuDisabled: boolean;
    loadingPilets: boolean;
    isMenuOpen: boolean;
  } // interface augmentation for the global state
}

// plugins
export const piralPlugins = [
  createMyApi(),
  createLocaleApi(Localizer.getInstance().getLocalizable()),
];

// start loading pilets async if the current path is the welcome page
export const loadPiletAsync = location.pathname === RoutesEnum.WELCOME_PAGE;

export const requestPilets = async (appshellInstance: PiralInstance) => {
  const { root } = appshellInstance;
  const { setLoadingPilets, setMenuDisabled } = root;
  setMenuDisabled(true);
  setLoadingPilets(true);
  if (location.href.indexOf(RoutesEnum.WELCOME_PAGE) == -1) {
    try {
      const res = await piletService.get<any>("/");
      return res.data.items;
    } catch (e) {
      console.error("Error loading pilets", e);
    } finally {
      setLoadingPilets(false);
      setMenuDisabled(false);
    }
  } else {
    return [];
  }
};

/**
 * Main instance - it is the main entry point for the Piral instance.
 * It is used to create a new Piral instance with the specified configuration.
 * The configuration is an object that contains the initial state, the available actions, and the plugins.
 * Dependencies are not provided here directly but are resolved by the package manager. ( see package.json - importmap )
 * The instance is then used to render the Piral app.
 */
const instance = createInstance({
  state: {
    components: layout,
    errorComponents: errors,
    menuDisabled: false,
    loadingPilets: false,
    isMenuOpen: false,
    routes: {
      "/": home,
      "/welcome": () => <HomePageUI pathType={location.href} />,
      "/undefined": home,
    },
  },
  actions,
  requestPilets: async () => await requestPilets(instance),
  async: loadPiletAsync,
  plugins: piralPlugins,
});

// get the createApi creator from the instance
const { createApi } = instance;

// create the appshell api -> use usePiletApi hook to access the appshell api
const appshellApi = createApi({
  link: "",
  requireRef: "",
  spec: "v2",
  name: "@gnv/resevo-app-shell",
  version: "2",
  dependencies: {},
  config: {},
  basePath: "",
});

//initialize google tag manager to be globally available
const { initialize } = useGtm();
initialize();

// initialize the appshell util - used to share the appshell instance and the appshell api within the app-shell,
// deprecated -> now the appshell api is available under a hook usePiletApi
AppshellUtil.init(instance, appshellApi);

// Create a custom Emotion cache that will insert styles at the end of <body>, avoiding conflict with our styles that have been loaded before
// since emotion append styles at the end of <head> at runtime
const emotionCache = createCache({ key: "custom", prepend: true });

// finally, render the app
const rootElement = document.querySelector("#app");
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <CacheProvider value={emotionCache}>
      <Piral instance={instance} />
    </CacheProvider>,
  );
}
