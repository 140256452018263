import React from "react";
import SplashPageCard from "../../components/splashPageCard/SplashPageCard";
import "./splashPage.scss";
import SplashImage from "../../static/images/splash-page.png";
import Box from "@mui/material/Box";
import { UserEnum } from "../../constants/constantEnums";

type SplashPageProps = {
  userAccountType: string;
  username: string;
};

const SplashPage: React.FC<SplashPageProps> = ({
  username = "",
  userAccountType = "",
}) => {
  const isExternalUser =
    userAccountType && userAccountType === UserEnum.EXTERNAL;
  // const getUserName = isExternalUser ? "" : username;

  return (
    <Box
      className="splash-page-container"
      sx={{ justifyContent: isExternalUser ? "space-between" : "flex-start" }}
    >
      <Box className="splash-page-left-section">
        <SplashPageCard userName={username} userAccountType={userAccountType} />
      </Box>
      <Box
        className={
          isExternalUser
            ? "splash-page-right-section-adv"
            : "splash-page-right-section"
        }
      >
        {!isExternalUser && (
          <Box
            component="img"
            src={SplashImage}
            alt="splash-image"
            className="splash-image"
          />
        )}
      </Box>
    </Box>
  );
};

export default SplashPage;
