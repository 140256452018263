enum RoutesEnum {
  HOME = "/",
  WELCOME_PAGE = "/welcome",
  OPERATIONAL_VARIATIONS = "/utilities/operational-variations",
  OPERATIONS_SUMMARY = "/utilities/operations-summary",
  ACCOUNTING_SUMMARY = "/utilities/accounting-summary",
  ACCOUNTING_DOCUMENTS = "/utilities/accounting-documents",
  MOVEMENTS_LIST = "/utilities/movements-list",
  BOOKING_EMIT = "/booking/emit",
  BOOKING_EDIT = "/booking/edit",
  ORDER_MANAGEMENT_PBL = "/booking/order-management-pbl",
  BO_CUSTOMER_CARE = "/back-office/customer-care",
  SALES_REPORT = "/booking/sales-report",
  BOOKING_CALENDAR = "/booking/calendar",
  CHECKIN = "/check-in-boarding-pax/check-in",
  PREPAID_AGENCY_INFO = "/booking/agency-prepaid-headers/agency-info/",
  FEATURES = "/features",
}

export default RoutesEnum;
