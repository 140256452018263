export const translations = {
  itIT: {
    deDE: "Tedesco",
    enUS: "Inglese",
    esES: "Spagnolo",
    frFR: "Francese",
    itIT: "Italiano",
    sqAL: "Albanese",
    "appshell-accounting-summary-title": "Riepilogo contabile",
    "appshell-accounting-summary": "Riepilogo contabile",
    "appshell-adv": "Adv",
    "appshell-agency": "Agenzia",
    "appshell-amount": "Importo",
    "appshell-assic": "Assic",
    "appshell-comm-bile": "Comm.bile",
    "appshell-crs": "CRS",
    "appshell-departure": "Partenza",
    "appshell-departures-month": "Partenze mese di",
    "appshell-end-date": "Data fine",
    "appshell-exclude-movements": "Esclude movimenti già acquistati",
    "appshell-export": "Esporta",
    "appshell-holder": "Titolare",
    "appshell-home-title": "Resevo Backoffice Home Page",
    "appshell-home-welcome": "Benvenuto nella pagina di backoffice",
    "appshell-mark-movements": "Marca movimenti acquistati",
    "appshell-menu-accounting-summary": "Riepilogo Contabile",
    "appshell-menu-agencies-management": "Gestione agenzie",
    "appshell-menu-blacklist": "Gestione black list passeggeri",
    "appshell-menu-reversals-management": "Gestione reversali",
    "appshell-menu-management-sales-grid": "Gestione griglia sconti",
    "appshell-menu-agency": "Agenzie",
    "appshell-menu-booked-report": "Report prenotato",
    "appshell-menu-coupon-covid": "Coupon Covid",
    "appshell-menu-create-ticket": "Biglietti Emissione",
    "appshell-menu-customer-registry": "Anagrafica clienti",
    "appshell-menu-edit-ticket": "Biglietti Modifica",
    "appshell-menu-order-management" : "Gestione ordini PBL",
    "appshell-menu-bo-customer-care": "Backoffice CC",
    "appshell-menu-sales-report": "Report vendite",
    "appshell-menu-freight-management": "Gestione mezzi",
    "appshell-menu-freight-booking": "Gestione booking",
    "appshell-menu-groups-management": "Gruppi Gestione",
    "appshell-menu-groups-report": "Report grupi",
    "appshell-menu-groups": "Gruppi",
    "appshell-menu-manage-voys-generation": "Generazione Operativo Viaggi",
    "appshell-menu-agencies-sub-codes-management": "Gestione Sottocodici",
    "appshell-menu-manage-voys": "Viaggi gestione",
    "appshell-menu-monitor": "Monitor GNV",
    "appshell-menu-movements-list": "Elenco Movimenti Add/Cred",
    "appshell-menu-operational-variations": "Variazioni Operative",
    "appshell-menu-operations-summary": "Riepilogo Operazioni",
    "appshell-menu-ports": "Porti",
    "appshell-menu-sales-categories-management": "Gestione Categoria Vendita",
    "appshell-menu-search-ticket": "Ricerca biglietto",
    "appshell-menu-sells-report": "report vendite",
    "appshell-menu-ships-registry": "Anagrafica navi",
    "appshell-menu-ships-resources-management": "Gestione risorse nave",
    "appshell-menu-ticket": "Biglietti",
    "appshell-menu-travel-count": "Conteggio viaggi",
    "appshell-menu-utilities": "Utilità",
    "appshell-menu-voys-calendar": "Calendario partenze",
    "appshell-menu-voys": "Viaggi",
    "appshell-movements-list-add": "Elenco movimenti add",
    "appshell-movements-list-title": "Elenco movimenti addebito/accredito",
    "appshell-movements-list": "Elenco movimenti addebito/accredito",
    "appshell-new-operative": "Nuovo operativo",
    "appshell-operation-date": "Data operazione",
    "appshell-operational-variations-title":
      "Biglietti su partenze annullate/variate",
    "appshell-operations-summary-title": "Riepilogo operazioni",
    "appshell-operations-summary": "Riepilogo operazioni",
    "appshell-original-operative": "Operativo originale",
    "appshell-penalties": "Penali",
    "appshell-phone": "Telefono",
    "appshell-provvi": "Provvi",
    "appshell-rights": "Diritti",
    "appshell-route": "Percorso",
    "appshell-search": "Ricerca",
    "appshell-services": "Servizi",
    "appshell-start-date": "Data inizio",
    "appshell-taxes": "Tasse",
    "appshell-ticket": "Biglietto",
    language: "Lingua",
    "appshell-accounting-documents": "Documenti contabili",
    "language-picker-label": "Lingua utilizzata",
    "appshell-change-lan": "Cambio lingua",
    "appshell-change-lan-description":
      "Il cambio avrà effetto immediato su tutto Resevo",
    "appshell-menu-agencies-user-management": "Gestione utenze",
    welcome: "Benvenuto",
    "appshell-year": "Anno",
    "appshell-file": "File",
    "need-help": "Hai bisogno di aiuto?",
    "support-contact": "Contatta il supporto",
    "use-guide": "Guida all'uso",
    home: "Home",
    loading: "Attendi il Caricamento",
    "login-title-external": "Entra in GNV",
    "login-title-internal": "Entra in Resevo",
    "login-button": "LOGIN",
    "login-info": "Per recuperare mail e password clicca su LOGIN",
    "footer-business-name": "Grandi Navi Veloci S.p.A.",
    "footer-vat-number": "P.IVA 13217910150",
    "footer-info-1": "Condizioni Generali",
    "footer-info-2": "Cookie Policy",
    "footer-info-3": "Diritti dei passeggeri",
    "footer-info-4": "Disiscrizione Promozioni",
    "footer-info-5": "Modello di organizzazione, gestione e controllo",
    "footer-info-6": "Norme per l’uso",
    "footer-info-7": "Privacy",
    "footer-info-8": "Reclami",
    "404-error-message":
      "Spiacenti, sembra che la pagina che stavi cercando non sia disponibile",
    "report-print": "La stampa",
    "report-agencies": "dell'elenco agenzie",
    "report-full-message":
      "è terminata. Il link per il download sarà inviato via email.",
    "app-shell-checkin": "Biglietti checkin",
    "checkin-boarding-pax": "Checkin & Boarding Pax",
    "appshell-menu-cc-transactions-list": "Elenco Transazioni C/Credito",
    "appshell-export-list": "Esporta lista",
    "remaining-credit": "Credito residuo: ",
  },
  enUS: {
    deDE: "German",
    enUS: "English",
    esES: "Spanish",
    frFR: "French",
    itIT: "Italian",
    sqAL: "Albanian",
    "appshell-accounting-summary-title": "Accounting summary",
    "appshell-accounting-summary": "Accounting summary",
    "appshell-adv": "Adv agencies",
    "appshell-agency": "Agency",
    "appshell-amount": "Amount",
    "appshell-assic": "Insurance",
    "appshell-comm-bile": "Commissionable",
    "appshell-crs": "CRS",
    "appshell-departure": "Departure",
    "appshell-departures-month": "Departures month of",
    "appshell-end-date": "End date",
    "appshell-exclude-movements": "Excludes already acquired transactions",
    "appshell-export": "Export",
    "appshell-holder": "Holder",
    "appshell-home-title": "Resevo Back-office Home Page",
    "appshell-home-welcome": "Welcome to the back-office page",
    "appshell-mark-movements": "Mark acquired transactions",
    "appshell-menu-accounting-summary": "Accounting Summary",
    "appshell-menu-agencies-management": "Agency management",
    "appshell-menu-blacklist": "Blacklist passengers management",
    "appshell-menu-reversals-management": "Reversals management",
    "appshell-menu-management-sales-grid": "Management sales grid",
    "appshell-menu-agency": "Agencies",
    "appshell-menu-booked-report": "Report booked",
    "appshell-menu-coupon-covid": "Covid Coupon",
    "appshell-menu-create-ticket": "Tickets Issue",
    "appshell-menu-customer-registry": "Client master data information",
    "appshell-menu-edit-ticket": "Edit Tickets",
    "appshell-menu-order-management" : "Order Management PBL",
    "appshell-menu-bo-customer-care": "Backoffice CC",
    "appshell-menu-sales-report": "Sales report",
    "appshell-menu-freight-management": "Vehicle management",
    "appshell-menu-groups-management": "Groups Management",
    "appshell-menu-groups-report": "Group reports",
    "appshell-menu-groups": "Groups",
    "appshell-menu-manage-voys-generation": "Voyage Operation Generation",
    "appshell-menu-agencies-sub-codes-management": "Sub-code Management",
    "appshell-menu-manage-voys": "Voyage management",
    "appshell-menu-monitor": "GNV monitor",
    "appshell-menu-movements-list": "List of Deb/Cr Transactions",
    "appshell-menu-operational-variations": "Operational Changes",
    "appshell-menu-operations-summary": "Operations Summary",
    "appshell-menu-ports": "Ports",
    "appshell-menu-sales-categories-management": "Sales Category Management",
    "appshell-menu-search-ticket": "Ticket search",
    "appshell-menu-sells-report": "sales report",
    "appshell-menu-ships-registry": "Ship registry",
    "appshell-menu-ships-resources-management": "Ship resource management",
    "appshell-menu-ticket": "Tickets",
    "appshell-menu-travel-count": "Voyage count",
    "appshell-menu-utilities": "Utility",
    "appshell-menu-voys-calendar": "Departure calendar",
    "appshell-menu-voys": "Voyages",
    "appshell-movements-list-add": "List of debit transactions",
    "appshell-movements-list-title": "List of debit/credit transactions",
    "appshell-movements-list": "List of debit/credit transactions",
    "appshell-new-operative": "New operating item",
    "appshell-operation-date": "Operation date",
    "appshell-operational-variations-title":
      "Tickets for cancelled/changed departures",
    "appshell-operations-summary-title": "Operations summary",
    "appshell-operations-summary": "Operations summary",
    "appshell-original-operative": "Original operating item",
    "appshell-penalties": "Penalties",
    "appshell-phone": "Phone",
    "appshell-provvi": "Commission",
    "appshell-rights": "Rights",
    "appshell-route": "Route",
    "appshell-search": "Search",
    "appshell-services": "Services",
    "appshell-start-date": "Start date",
    "appshell-taxes": "Taxes",
    "appshell-ticket": "Ticket",
    language: "Language",
    "appshell-accounting-documents": "Accounting documents",
    "language-picker-label": "Language used",
    "appshell-change-lan": "Change language",
    "appshell-change-lan-description":
      "The change will take effect immediately across all Resevo",
    "appshell-menu-agencies-user-management": "Utility management",
    welcome: "Welcome",
    "appshell-year": "Year",
    "appshell-file": "File",
    "need-help": "Do you need help?",
    "support-contact": "Contact support",
    "use-guide": "User guide",
    home: "Home",
    loading: "Wait for Loading",
    "login-title-external": "Enter GNV",
    "login-title-internal": "Enter Resevo",
    "login-button": "LOGIN",
    "login-info": "To recover your email and password, click on LOGIN",
    "footer-business-name": "Grandi Navi Veloci S.p.A.",
    "footer-vat-number": "VAT NO. 13217910150",
    "footer-info-1": "General Conditions",
    "footer-info-2": "Cookie Policy",
    "footer-info-3": "Passenger rights",
    "footer-info-4": "Unsubscribe from Promotions",
    "footer-info-5": "Organisation, management and control model",
    "footer-info-6": "Rules for use",
    "footer-info-7": "Privacy",
    "footer-info-8": "Complaints",
    "404-error-message":
      "Sorry, it appears the page you were looking for is not available",
    "report-print": "The print",
    "report-agencies": "of the agency list",
    "report-full-message":
      "is finished. The download link will be sent via email.",
    "app-shell-checkin": "Check-in tickets",
    "checkin-boarding-pax": "Pax Check-in &amp; Boarding",
    "appshell-menu-cc-transactions-list": "Elenco Transazioni C/Credito",
    "appshell-export-list": "Esporta lista",
    "remaining-credit": "Credito residuo: ",
    "appshell-menu-freight-booking": "Gestione booking",
  },
  frFR: {
    deDE: "Allemand",
    enUS: "Anglais",
    esES: "Espagnol",
    frFR: "Français",
    itIT: "Italien",
    sqAL: "Albanais",
    "appshell-accounting-summary-title": "Résumé comptable",
    "appshell-accounting-summary": "Résumé comptable",
    "appshell-adv": "Agences",
    "appshell-agency": "Agence",
    "appshell-amount": "Montant",
    "appshell-assic": "Assurance",
    "appshell-comm-bile": "Commission",
    "appshell-crs": "CRS",
    "appshell-departure": "Départ",
    "appshell-departures-month": "Départs mois de",
    "appshell-end-date": "Date fin",
    "appshell-exclude-movements": "Exclut les mouvements déjà achetés",
    "appshell-export": "Exporter",
    "appshell-holder": "Titulaire",
    "appshell-home-title": "Page d'accueil du back-office de Resevo",
    "appshell-home-welcome": "Bienvenue sur la page de back-office",
    "appshell-mark-movements": "Marque mouvements achetés",
    "appshell-menu-accounting-summary": "Résumé comptable",
    "appshell-menu-agencies-management": "Gestion agences",
    "appshell-menu-blacklist": "Blacklist passengers management",
    "appshell-menu-reversals-management": "Gestione reversali",
    "appshell-menu-management-sales-grid": "Management sales grid",
    "appshell-menu-agency": "Agences",
    "appshell-menu-booked-report": "Rapport réservé",
    "appshell-menu-coupon-covid": "Coupon Covid",
    "appshell-menu-create-ticket": "Émission billets",
    "appshell-menu-customer-registry": "Registre clients",
    "appshell-menu-edit-ticket": "Modifier billets",
    "appshell-menu-order-management" : "Gestion des commandes PBL",
    "appshell-menu-bo-customer-care": "Backoffice CC",
    "appshell-menu-sales-report": "Rapport de ventes",
    "appshell-menu-freight-management": "Gestion des véhicules",
    "appshell-menu-groups-management": "Groupes de gestion",
    "appshell-menu-groups-report": "Rapport groupes",
    "appshell-menu-groups": "Groupes",
    "appshell-menu-manage-voys-generation": "Génération d'opérations de voyage",
    "appshell-menu-agencies-sub-codes-management": "Gestion des sous-codes",
    "appshell-menu-manage-voys": "Gestion des voyages",
    "appshell-menu-monitor": "Moniteur GNV",
    "appshell-menu-movements-list": "Liste des mouvements Débit/Crédit",
    "appshell-menu-operational-variations": "Changements opérationnels",
    "appshell-menu-operations-summary": "Résumé des opérations",
    "appshell-menu-ports": "Ports",
    "appshell-menu-sales-categories-management": "Gestion catégorie de vente",
    "appshell-menu-search-ticket": "Recherche de billet",
    "appshell-menu-sells-report": "rapport de ventes",
    "appshell-menu-ships-registry": "Registre bateaux",
    "appshell-menu-ships-resources-management":
      "Gestion des ressources du bateau",
    "appshell-menu-ticket": "Billets",
    "appshell-menu-travel-count": "Nombre de voyages",
    "appshell-menu-utilities": "Utilité",
    "appshell-menu-voys-calendar": "Calendrier des départs",
    "appshell-menu-voys": "Voyages",
    "appshell-movements-list-add": "Liste des mouvements de débit",
    "appshell-movements-list-title": "Liste des mouvements de débit/crédit",
    "appshell-movements-list": "Liste des mouvements de débit/crédit",
    "appshell-new-operative": "Nouvelle exploitation",
    "appshell-operation-date": "Date opération",
    "appshell-operational-variations-title":
      "Billets sur les départs annulés/modifiés",
    "appshell-operations-summary-title": "Résumé des opérations",
    "appshell-operations-summary": "Résumé des opérations",
    "appshell-original-operative": "Exploitation d'origine",
    "appshell-penalties": "Pénalités",
    "appshell-phone": "Téléphone",
    "appshell-provvi": "Commission",
    "appshell-rights": "Droits",
    "appshell-route": "Itinéraire",
    "appshell-search": "Recherche",
    "appshell-services": "Services",
    "appshell-start-date": "Date de début",
    "appshell-taxes": "Taxes",
    "appshell-ticket": "Billet",
    language: "Langue",
    "appshell-accounting-documents": "Documents comptables",
    "language-picker-label": "Langue utilisée",
    "appshell-change-lan": "Changement de langue",
    "appshell-change-lan-description":
      "Le changement prendra effet immédiatement dans tout Resevo",
    "appshell-menu-agencies-user-management": "Gestion des utilisateurs",
    welcome: "Bienvenue",
    "appshell-year": "Année",
    "appshell-file": "Déposer",
    "need-help": "Avez-vous besoin d'aide ?",
    "support-contact": "Contactez le support",
    "use-guide": "Mode d'emploi",
    home: "Accueil",
    loading: "Attendez le chargement",
    "login-title-external": "Entrez dans GNV",
    "login-title-internal": "Entrez dans Resevo",
    "login-button": "LOGIN",
    "login-info": "Pour récupérer email et mot de passe, cliquez sur LOGIN",
    "footer-business-name": "Grandi Navi Veloci S.p.A.",
    "footer-vat-number": "NUMÉRO DE TVA 13217910150",
    "footer-info-1": "Conditions générales",
    "footer-info-2": "Politique en matière de cookies",
    "footer-info-3": "Droits des passagers",
    "footer-info-4": "Désabonnement des promotions",
    "footer-info-5": "Modèle d’organisation, de gestion et de contrôle",
    "footer-info-6": "Règles d'utilisation",
    "footer-info-7": "Confidentialité",
    "footer-info-8": "Réclamations",
    "404-error-message":
      "Désolé, il semble que la page que vous recherchez n'est pas disponible",
    "report-print": "L’impression",
    "report-agencies": "de la liste des agences",
    "report-full-message":
      "est terminée. Le lien pour le téléchargement sera envoyé par e-mail.",
    "app-shell-checkin": "Billets d'enregistrement",
    "checkin-boarding-pax": "Enregistrement &amp; Embarquement passager",
    "appshell-menu-cc-transactions-list": "Elenco Transazioni C/Credito",
    "appshell-export-list": "Esporta lista",
    "remaining-credit": "Credito residuo: ",
    "appshell-menu-freight-booking": "Gestione booking",
  },
  deDE: {
    deDE: "Deutsch",
    enUS: "Englisch",
    esES: "Spanisch",
    frFR: "Französisch",
    itIT: "Italienisch",
    sqAL: "Albanisch",
    "appshell-accounting-summary-title": "Zusammenfassung der Abrechnung",
    "appshell-accounting-summary": "Zusammenfassung der Abrechnung",
    "appshell-adv": "Reiseb",
    "appshell-agency": "Reisebüro",
    "appshell-amount": "Betrag",
    "appshell-assic": "Versicherung",
    "appshell-comm-bile": "Provisionspflichtig",
    "appshell-crs": "CRS",
    "appshell-departure": "Abreise",
    "appshell-departures-month": "Abreise Monat",
    "appshell-end-date": "Datum des Endes",
    "appshell-exclude-movements": "Ausgenommen sind bereits gekaufte Reisen",
    "appshell-export": "Exportieren",
    "appshell-holder": "Inhaber",
    "appshell-home-title": "Resevo Backoffice Home Page",
    "appshell-home-welcome": "Willkommen auf der Backoffice-Seite",
    "appshell-mark-movements": "Gekaufte Reisen markieren",
    "appshell-menu-accounting-summary": "Zusammenfassung der Abrechnung",
    "appshell-menu-agencies-management": "Verwaltung Reisebüro",
    "appshell-menu-blacklist": "Blacklist passengers management",
    "appshell-menu-reversals-management": "Gestione reversali",
    "appshell-menu-management-sales-grid": "Management sales grid",
    "appshell-menu-agency": "Reisebüros",
    "appshell-menu-booked-report": "Bericht gebucht",
    "appshell-menu-coupon-covid": "Covid-Coupon",
    "appshell-menu-create-ticket": "Ausgestellte Tickets",
    "appshell-menu-customer-registry": "Kundendaten",
    "appshell-menu-edit-ticket": "Ticket ändern",
    "appshell-menu-order-management" : "Order Management PBL",
    "appshell-menu-bo-customer-care": "Backoffice CC",
    "appshell-menu-sales-report": "Verkaufsbericht",
    "appshell-menu-freight-management": "Fahrzeugverwaltung",
    "appshell-menu-groups-management": "Gruppen Verwaltung",
    "appshell-menu-groups-report": "Berichte Gruppen",
    "appshell-menu-groups": "Gruppen",
    "appshell-menu-manage-voys-generation": "Erstellung Vorgang Reisen",
    "appshell-menu-agencies-sub-codes-management": "Subcode-Verwaltung",
    "appshell-menu-manage-voys": "Reiseverwaltung",
    "appshell-menu-monitor": "GNV-Ansicht",
    "appshell-menu-movements-list": "Liste Bewegungen Belastung/Gutschrift",
    "appshell-menu-operational-variations": "Vorgangsänderungen",
    "appshell-menu-operations-summary": "Zusammenfassung der Vorgänge",
    "appshell-menu-ports": "Häfen",
    "appshell-menu-sales-categories-management":
      "Verwaltung der Verkaufskategorien",
    "appshell-menu-search-ticket": "Ticket-Suche",
    "appshell-menu-sells-report": "Verkaufsbericht",
    "appshell-menu-ships-registry": "Schiffsdaten",
    "appshell-menu-ships-resources-management":
      "Verwaltung der Schiffsressourcen",
    "appshell-menu-ticket": "Tickets",
    "appshell-menu-travel-count": "Reisezählung",
    "appshell-menu-utilities": "Dienste",
    "appshell-menu-voys-calendar": "Abfahrtskalender",
    "appshell-menu-voys": "Reisen",
    "appshell-movements-list-add": "Buchungsliste Belastungen",
    "appshell-movements-list-title": "Buchungsliste Belastungen/Gutschriften",
    "appshell-movements-list": "Buchungsliste Belastungen/Gutschriften",
    "appshell-new-operative": "Neuer Vorgang",
    "appshell-operation-date": "Datum des Vorgangs",
    "appshell-operational-variations-title":
      "Tickets für stornierte/geänderte Abfahrten",
    "appshell-operations-summary-title": "Zusammenfassung Vorgänge",
    "appshell-operations-summary": "Zusammenfassung Vorgänge",
    "appshell-original-operative": "Ursprünglicher Vorgang",
    "appshell-penalties": "Stornogebühren",
    "appshell-phone": "Telefon",
    "appshell-provvi": "Provi",
    "appshell-rights": "Rechte",
    "appshell-route": "Route",
    "appshell-search": "Suche",
    "appshell-services": "Serviceleistungen",
    "appshell-start-date": "Datum des Beginns",
    "appshell-taxes": "Gebühren",
    "appshell-ticket": "Ticket",
    language: "Sprache",
    "appshell-accounting-documents": "Buchhaltungs-Dokumente",
    "language-picker-label": "Verwendete Sprache",
    "appshell-change-lan": "Änderung der Sprache",
    "appshell-change-lan-description":
      "Die Änderung gilt ab sofort für den gesamten Resevo-Bereich",
    "appshell-menu-agencies-user-management": "Verwaltung von Diensten",
    welcome: "Willkommen",
    "appshell-year": "Jahr",
    "appshell-file": "Datei",
    "need-help": "Brauchen Sie Hilfe?",
    "support-contact": "Kontakt zum Support",
    "use-guide": "Benutzerhandbuch",
    home: "Startseite",
    loading: "Warten auf Laden",
    "login-title-external": "GNV aufrufen",
    "login-title-internal": "Resevo aufrufen",
    "login-button": "ANMELDEN",
    "login-info":
      "Um Ihre E-Mail und Ihr Passwort abzurufen, klicken Sie auf ANMELDEN",
    "footer-business-name": "Grandi Navi Veloci S.p.A.",
    "footer-vat-number": "USt.-Nr. 13217910150",
    "footer-info-1": "Allgemeine Geschäftsbedingungen",
    "footer-info-2": "Cookie-Richtlinie",
    "footer-info-3": "Passagierrechte",
    "footer-info-4": "Abmelden von Aktionsbenachrichtigungen",
    "footer-info-5": "Organisations-, Verwaltungs- und Kontrollmodell",
    "footer-info-6": "Regeln für die Nutzung",
    "footer-info-7": "Datenschutz",
    "footer-info-8": "Beschwerden",
    "404-error-message":
      "Leider ist die von Ihnen gesuchte Seite nicht verfügbar",
    "report-print": "Druck",
    "report-agencies": "der Reisebüroliste",
    "report-full-message":
      "ist beendet. Der Download-Link wird Ihnen per E-Mail zugesandt.",
    "app-shell-checkin": "Tickets einchecken",
    "checkin-boarding-pax": "Check-in &amp; Boarding Pax",
    "appshell-menu-cc-transactions-list": "Elenco Transazioni C/Credito",
    "appshell-export-list": "Esporta lista",
    "remaining-credit": "Credito residuo: ",
    "appshell-menu-freight-booking": "Gestione booking",
  },
  sqAL: {
    deDE: "Gjermanisht",
    enUS: "Anglisht",
    esES: "Spanjisht",
    frFR: "Frëngjisht",
    itIT: "Italisht",
    sqAL: "Shqip",
    "appshell-accounting-summary-title": "Përmbledhja e kontabilitetit",
    "appshell-accounting-summary": "Përmbledhja e kontabilitetit",
    "appshell-adv": "Agjencia",
    "appshell-agency": "Agjencia",
    "appshell-amount": "Shuma",
    "appshell-assic": "Sigurimi",
    "appshell-comm-bile": "E komisionueshme",
    "appshell-crs": "CRS",
    "appshell-departure": "Nisja",
    "appshell-departures-month": "Nisja në muajin",
    "appshell-end-date": "Data e përfundimit",
    "appshell-exclude-movements": "Përjashton lëvizjet tashmë të blera",
    "appshell-export": "Eksporto",
    "appshell-holder": "Titullari",
    "appshell-home-title": "Kryefaqja Backoffice Resevo",
    "appshell-home-welcome": "Mirë se vini në faqen e backoffice",
    "appshell-mark-movements": "Shëno lëvizjet e blera",
    "appshell-menu-accounting-summary": "Përmbledhja e kontabilitetit",
    "appshell-menu-agencies-management": "Administrimi i agjencive",
    "appshell-menu-blacklist": "Blacklist passengers management",
    "appshell-menu-reversals-management": "Gestione reversali",
    "appshell-menu-management-sales-grid": "Management sales grid",
    "appshell-menu-agency": "Agjencitë",
    "appshell-menu-booked-report": "Raporti është rezervuar",
    "appshell-menu-coupon-covid": "Kupon Kovid",
    "appshell-menu-create-ticket": "Nxjerrja e biletave",
    "appshell-menu-customer-registry": "Të dhënat anagrafe të klientëve",
    "appshell-menu-edit-ticket": "Modifiko biletën",
    "appshell-menu-order-management" : "Administrim i porosive PBL",
    "appshell-menu-bo-customer-care": "Backoffice CC",
    "appshell-menu-sales-report": "Raporti i shitjeve",
    "appshell-menu-freight-management": "Administrimi i mjeteve",
    "appshell-menu-groups-management": "Administrimi i grupeve",
    "appshell-menu-groups-report": "Raporti i grupit",
    "appshell-menu-groups": "Grupet",
    "appshell-menu-manage-voys-generation":
      "Gjenerimi i veprimit të udhëtimeve",
    "appshell-menu-agencies-sub-codes-management": "Gjenerimi i nënkodeve",
    "appshell-menu-manage-voys": "Administrimi i udhëtimeve",
    "appshell-menu-monitor": "Monitorim GNV",
    "appshell-menu-movements-list": "Lista e lëvizjeve Shto/Krijo",
    "appshell-menu-operational-variations": "Variacionet e veprimeve",
    "appshell-menu-operations-summary": "Përmbledhje e veprimeve",
    "appshell-menu-ports": "Porte",
    "appshell-menu-sales-categories-management":
      "Administrimi i kategorisë së shitjes",
    "appshell-menu-search-ticket": "Kërko biletën",
    "appshell-menu-sells-report": "raporti i shitjeve",
    "appshell-menu-ships-registry": "Të dhënat anagrafe të anijeve",
    "appshell-menu-ships-resources-management":
      "Administrimi i burimeve të anijes",
    "appshell-menu-ticket": "Bileta",
    "appshell-menu-travel-count": "Numërimi i udhëtimeve",
    "appshell-menu-utilities": "Dobia",
    "appshell-menu-voys-calendar": "Kalendari i nisjeve",
    "appshell-menu-voys": "Udhëtime",
    "appshell-movements-list-add": "Lista e lëvizjeve të tarifimit",
    "appshell-movements-list-title": "Lista e lëvizjeve të tarifimit/pagimit",
    "appshell-movements-list": "Lista e lëvizjeve të tarifimit/pagimit",
    "appshell-new-operative": "Veprim i ri",
    "appshell-operation-date": "Data e veprimit",
    "appshell-operational-variations-title":
      "Biletat për nisjen e anuluara/ndryshuara",
    "appshell-operations-summary-title": "Përmbledhje e veprimeve",
    "appshell-operations-summary": "Përmbledhje e veprimeve",
    "appshell-original-operative": "Veprimi origjinal",
    "appshell-penalties": "Gjoba",
    "appshell-phone": "Telefon",
    "appshell-provvi": "Përkohshme",
    "appshell-rights": "Të drejta",
    "appshell-route": "Rrugëtimi",
    "appshell-search": "Kërko",
    "appshell-services": "Shërbime",
    "appshell-start-date": "Data e fillimit",
    "appshell-taxes": "Taksa",
    "appshell-ticket": "Biletë",
    language: "Gjuhe",
    "appshell-accounting-documents": "Dokumentet e kontabilitetit",
    "language-picker-label": "Gjuha e përdorur",
    "appshell-change-lan": "Ndryshimi i gjuhës",
    "appshell-change-lan-description":
      "Ndryshimi do të ketë efekt të menjëhershëm tek i gjithë Resevo",
    "appshell-menu-agencies-user-management": "Administrimi i përdoruesve",
    welcome: "Mirë se vini",
    "appshell-year": "viti",
    "appshell-file": "Skedari",
    "need-help": "Keni nevojë për ndihmë?",
    "support-contact": "Kontaktoni asistencën",
    "use-guide": "Udhëzues përdorimi",
    home: "Kreu",
    loading: "Prisni sa të karikohet",
    "login-title-external": "Hyni në GNV",
    "login-title-internal": "Hyni në Resevo",
    "login-button": "IDENTIFIKOHU",
    "login-info":
      "Për të rikuperuar emailin dhe fjalëkalimin klikoni tek IDENTIFIKOHU",
    "footer-business-name": "Grandi Navi Veloci S.p.A.",
    "footer-vat-number": "NIPT 13217910150",
    "footer-info-1": "Kushtet e përgjithshme",
    "footer-info-2": "Politika e skedarëve të personalizimit",
    "footer-info-3": "Të drejtat e pasagjerëve",
    "footer-info-4": "Çregjistrimi nga promocionet",
    "footer-info-5": "Modeli i organizimit, menaxhimit dhe kontrollit",
    "footer-info-6": "Rregullat e përdorimit",
    "footer-info-7": "Privatësia",
    "footer-info-8": "Ankesat",
    "404-error-message":
      "Na vjen keq, faqja që po kërkoni nuk është e disponueshme",
    "report-print": "Printimi",
    "report-agencies": "i listës së agjencive",
    "report-full-message":
      "ka përfunduar. Lidhja për ta shkarkuar do të dërgohet me email.",
    "app-shell-checkin": "Biletat - Check-in",
    "checkin-boarding-pax": "Check-in dhe imbarkimi i pasagjerëve",
    "appshell-menu-cc-transactions-list": "Elenco Transazioni C/Credito",
    "appshell-export-list": "Esporta lista",
    "remaining-credit": "Credito residuo: ",
    "appshell-menu-freight-booking": "Gestione booking",
  },
  esES: {
    deDE: "Alemán",
    enUS: "Inglés",
    esES: "Español",
    frFR: "Francés",
    itIT: "Italiano",
    sqAL: "Albanés",
    "appshell-accounting-summary-title": "Resumen contable",
    "appshell-accounting-summary": "Resumen contable",
    "appshell-adv": "Agencias",
    "appshell-agency": "Agencia",
    "appshell-amount": "Importe",
    "appshell-assic": "Seguro",
    "appshell-comm-bile": "Comisionable",
    "appshell-crs": "CRS",
    "appshell-departure": "Salida",
    "appshell-departures-month": "Salidas mes de",
    "appshell-end-date": "Fecha final",
    "appshell-exclude-movements": "Excluir movimientos ya adquiridos",
    "appshell-export": "Exportar",
    "appshell-holder": "Titular",
    "appshell-home-title": "Página de inicio del backoffice de Resevo",
    "appshell-home-welcome": "Bienvenido a la página de backoffice",
    "appshell-mark-movements": "Marcar movimientos adquiridos",
    "appshell-menu-accounting-summary": "Resumen contable",
    "appshell-menu-agencies-management": "Gestión agencias",
    "appshell-menu-blacklist": "Blacklist passengers management",
    "appshell-menu-reversals-management": "Gestione reversali",
    "appshell-menu-management-sales-grid": "Management sales grid",
    "appshell-menu-agency": "Agencias",
    "appshell-menu-booked-report": "Informe reservado",
    "appshell-menu-coupon-covid": "Cupón covid",
    "appshell-menu-create-ticket": "Billetes emisión",
    "appshell-menu-customer-registry": "Registro de clientes",
    "appshell-menu-edit-ticket": "Editar Billetes",
    "appshell-menu-order-management" : "Gestión de pedidos PBL",
    "appshell-menu-bo-customer-care": "Backoffice CC",
    "appshell-menu-sales-report": "Informe de ventas",
    "appshell-menu-freight-management": "Gestión medios",
    "appshell-menu-groups-management": "Grupos gestión",
    "appshell-menu-groups-report": "Informe grupos",
    "appshell-menu-groups": "Grupos",
    "appshell-menu-manage-voys-generation": "Generación operativo viajes",
    "appshell-menu-agencies-sub-codes-management": "Gestión subcódigos",
    "appshell-menu-manage-voys": "Viajes gestión",
    "appshell-menu-monitor": "Monitor GNV",
    "appshell-menu-movements-list": "Lista de movimientos de débito/crédito",
    "appshell-menu-operational-variations": "Variaciones operativas",
    "appshell-menu-operations-summary": "Resumen de operaciones",
    "appshell-menu-ports": "Puertos",
    "appshell-menu-sales-categories-management": "Gestión Categoría de venta",
    "appshell-menu-search-ticket": "Buscar billete",
    "appshell-menu-sells-report": "informes de ventas",
    "appshell-menu-ships-registry": "Registro de barcos",
    "appshell-menu-ships-resources-management": "Gestión recursos barco",
    "appshell-menu-ticket": "Billetes",
    "appshell-menu-travel-count": "Recuento viajes",
    "appshell-menu-utilities": "Utilidades",
    "appshell-menu-voys-calendar": "Calendario de salidas",
    "appshell-menu-voys": "Viajes",
    "appshell-movements-list-add": "Lista de movimientos de débito",
    "appshell-movements-list-title": "Lista de movimientos de débito/crédito",
    "appshell-movements-list": "Lista de movimientos de débito/crédito",
    "appshell-new-operative": "Nuevo operativo",
    "appshell-operation-date": "Fecha de operación",
    "appshell-operational-variations-title":
      "Billetes en salidas canceladas/modificadas",
    "appshell-operations-summary-title": "Resumen de operaciones",
    "appshell-operations-summary": "Resumen de operaciones",
    "appshell-original-operative": "Operativo original",
    "appshell-penalties": "Sanciones",
    "appshell-phone": "Teléfono",
    "appshell-provvi": "Comisión",
    "appshell-rights": "Derechos",
    "appshell-route": "Recorrido",
    "appshell-search": "Búsqueda",
    "appshell-services": "Servicios",
    "appshell-start-date": "Fecha de inicio",
    "appshell-taxes": "Impuestos",
    "appshell-ticket": "Billete",
    language: "Idioma",
    "appshell-accounting-documents": "Documentos contables",
    "language-picker-label": "Idioma utilizado",
    "appshell-change-lan": "Cambio de idioma",
    "appshell-change-lan-description":
      "El cambio tendrá efecto inmediato en todo Resevo",
    "appshell-menu-agencies-user-management": "Gestión usuarios",
    welcome: "Bienvenido",
    "appshell-year": "Año",
    "appshell-file": "Archivo",
    "need-help": "¿Necesita ayuda?",
    "support-contact": "Contactar con el servicio de asistencia",
    "use-guide": "Guía del usuario",
    home: "Inicio",
    loading: "Cargando",
    "login-title-external": "Entrar en GNV",
    "login-title-internal": "Entrar en Resevo",
    "login-button": "INICIAR SESIÓN",
    "login-info":
      "Para recuperar el correo electrónico y la contraseña, haga clic en INICIAR SESIÓN",
    "footer-business-name": "Grandi Navi Veloci S.p.A.",
    "footer-vat-number": "N.º IVA 13217910150",
    "footer-info-1": "Condiciones generales",
    "footer-info-2": "Política de cookies",
    "footer-info-3": "Derechos de los pasajeros",
    "footer-info-4": "Cancelación de suscripción Promociones",
    "footer-info-5": "Modelo de organización, gestión y control",
    "footer-info-6": "Normas de uso",
    "footer-info-7": "Privacidad",
    "footer-info-8": "Reclamaciones",
    "404-error-message":
      "Lo sentimos, parece que la página que estaba buscando no está disponible",
    "report-print": "La impresión",
    "report-agencies": "de la lista de agencias",
    "report-full-message":
      "ha finalizado. El enlace para la descarga se enviará por correo electrónico.",
    "app-shell-checkin": "Billetes check-in",
    "checkin-boarding-pax": "Check-in y embarque de pasajeros",
    "appshell-menu-cc-transactions-list": "Elenco Transazioni C/Credito",
    "appshell-export-list": "Esporta lista",
    "remaining-credit": "Credito residuo: ",
    "appshell-menu-freight-booking": "Gestione booking",
  },
};
