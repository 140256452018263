export interface MyPiletApi {
  setLoadingPilets(val: boolean): void;
  setMenuDisabled(val: boolean): void;
  isMenuOpen: () => boolean;
}

export const createMyApi = (): ((context) => {
  setMenuDisabled(val: boolean): void;
  setLoadingPilets(val: boolean): void;
  isMenuOpen(): boolean;
}) => {
  return (context) => ({
    setLoadingPilets(val: boolean) {
      return context.dispatch((state) => ({
        ...state,
        loadingPilets: val,
      }));
    },

    setMenuDisabled(val: boolean) {
      return context.dispatch((state) => ({
        ...state,
        menuDisabled: val,
      }));
    },

    isMenuOpen(): boolean {
      return context.state.isMenuOpen;
    },
  });
};
